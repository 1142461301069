<template>
  <div>
    <div class="row mt-4 mb-4">
      <h1 class="col-sm-12 text-right">Configuración</h1>
    </div>

    <DataGrid :configuracion="config_datagrid" :data="configuraciones" @ready="buscar_botones">
      <div class="row">
        <div class="col-sm-12">
          <button class="btn btn-warning mr-2" @click="obtener_configuracion">Recargar</button>
          <button class="btn btn-success mr-2" @click="modal_nuevo=true">Agregar</button>
        </div>
      </div>
    </DataGrid>

    <Nuevo v-if="modal_nuevo" :configuracion="config" @update="update_configuraciones" @close="cancelar_configuracion" />
    <Eliminar v-if="modal_eliminar" :configuracion="config" @update="update_configuraciones" @close="cancelar_configuracion" />
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV3'
  import Nuevo from './Configuracion/Nuevo'
  import Eliminar from './Configuracion/Eliminar'

  import apiConfiguracion from '@/apps/formalizacion/api/v2/configuracion.js'

  export default {
    components: {
      DataGrid, Nuevo, Eliminar
    }
    ,data() {
      return {
        config_datagrid: {
          name: 'configuracion'
          ,cols: {
            clave: 'Clave'
            ,descripcion: 'Descripción'
            ,estatus: 'Estatus'
            ,prueba: {
              text: 'Opciones'
              ,ordeable: false
            }
          }
          ,mutators: {
            estatus(val) {
              return val == 1 ? 'Activo' : 'Inactivo';
            }
            ,prueba(val, row, vue) {
              return '<img class="mr-2 btn-opciones" data-action="editar" data-config="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar configuracion" title="Editar configuracion" />'
                  +  '<img class="btn-opciones" data-action="eliminar" data-config="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar configuracion" title="Elimitar configuracion" />';
            }
          }
          ,search: ['id','clave','descripcion','estatus']
          ,selector: false
        }
        ,configuraciones: []
        ,seleccionadas: []
        ,config: {
          clave: null
          ,descripcion: null
          ,valor: null
          ,estatus: 1
        }
        ,modal_nuevo: false
        ,modal_eliminar: false
      }
    }
    ,mounted() {
      this.obtener_configuracion();
    }
    ,methods: {
      async obtener_configuracion() {
        try {
          this.configuraciones = (await apiConfiguracion.obtener_concifigraciones()).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,row_seleccionadas(seleccionadas) {
        this.seleccionadas = seleccionadas;
      }
      ,buscar_botones() {
        let btns = document.querySelectorAll('.btn-opciones');
        
        btns.forEach(btn => {
          btn.removeEventListener('click', (e) => {
            this.ejecutar_accion(e);
          }, false);

          btn.addEventListener('click', (e) => {
            this.ejecutar_accion(e);
          }, false);
        })
      }
      ,ejecutar_accion(e) {
        let config = this.$helper.fromJson(e.target.dataset.config);
        let accion = e.target.dataset.action;
        
        this.config = config;
        if (accion == 'editar')
          this.modal_nuevo = true;
        else
          this.modal_eliminar = true;
      }
      ,update_configuraciones() {
        this.cancelar_configuracion();
        this.obtener_configuracion();
      }
      ,cancelar_configuracion() {
        this.config = {
          clave: null
          ,descripcion: null
          ,valor: null
          ,estatus: 1
        }
        this.modal_nuevo = false;
        this.modal_eliminar = false;
      }
    }
  }
</script>

<style lang="scss">
  table {
    tbody {
      tr {
        td {
          img {
            width: 20px;
          }
        }
      }
    }
  }
</style>